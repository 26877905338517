.production {
    background-color: #141b4d !important;
    z-index: 1199;
}

.hotfix {
    background-color: #D50032 !important;
    z-index: 1199;
}

.staging {
    background-color: #2DCCD3 !important;
    z-index: 1199;
}

.qa {
    background-color: orange !important;
    z-index: 1199;
}

.dev {
    background-color: green !important;
    z-index: 1199;
}

.header-logo {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
}

.logo-olef,
.logo-eurosport {
    height: 50px;
    margin:5px;
}

.logoName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 5px;
    gap: 10px;
    isolation: isolate;
    height: 22px;
    background: #009CDE;
    border-radius: 2.5026px;
    //font-family: 'Aeonik Eurosport';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-left: 5px;
}