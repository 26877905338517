@import "./colors";

.App {
  min-height: 100vh;
  background: #24232a;
}

.cursor-pointer {
  cursor: pointer;
}

.error {
  color: $error;
}

.searchFilter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

::-webkit-scrollbar {
  width: 9px;
  background-color: $darkModeScrollBar;
}

::-webkit-scrollbar-thumb {
  background: $darkModeScrollBarThumb;
  border-radius: 3px;
}

.help-block {
  font-size: 12px;
}

.MuiPaper-root {
  margin: 0 !important;
}

.MuiTableCell-body {
  color: white !important;
}

.table-striped:hover {
  background-color: #2e2d38 !important;
}

@font-face {
  font-family: "Aeonik Eurosport";
  src: url("./fonts/AeonikEurosport-Regular.woff2") format("woff2"),
    url("./fonts/AeonikEurosport-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Eurosport";
  src: url("./fonts/AeonikEurosport-Bold.woff2") format("woff2"),
    url("./fonts/AeonikEurosport-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.live {
  border-radius: 5px;
  background: #92001f;
  color: #fff;
  padding: 5px 8px;
  font-weight: 700;
}
.rx {
  border-radius: 5px;
  background: #3d6a00;
  color: #fff;
  padding: 5px 8px;
  font-weight: 700;
}
.replay {
  border-radius: 5px;
  background: #00458f;
  color: #fff;
  padding: 5px 8px;
  font-weight: 700;
}
.other {
  border-radius: 5px;
  background: #7b7b7e;
  color: #fff;
  padding: 5px 8px;
  font-weight: 700;
}
