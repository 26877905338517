@import "src/colors";

.grid {
  display: flex;
  justify-content: space-between;
  position: sticky;
  z-index: 1100;
  width: 100%;
  background-color: transparent;
}

.overflow-x {
  background: #24232a;
  overflow-x: auto;
  height: calc(100vh - 130px);
  @media screen and (max-width: 999px) {
    height: calc(100vh - 245px);
  }
}

.MuiTableBody-root {
  .MuiTableCell-root {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    height: 50px;
    border: none;
  }
}
.MuiTableHead-root {
  .MuiTableCell-root {
    border: none;
    font-weight: bold;
  }
}

.MuiTableCell-stickyHeader {
  background: #32323e !important;
}

.feedName {
  color: $feedName;
}

.Mui-selected {
  background-color: #bcc2ff !important;
}
