$navy: #141b4d;
$grey: #aeaeae;
$lightBlue: #e8eaf6;
$variantValidated: #66B366;
$variantWaiting: #dfdfdf;
$variantDeclined: #FF6666;
$timelineHeader: #78BB20;
$bgGrey: #f3f7fe;
$darkGrey: #A9A9A9;
$cardActive: lightblue;
$cardOver: lightgrey;
$iconHomeColor: #d50032;
$iconDeleteColor: #d50032;
$buttonSave: #39b253;
$success: #39b253;
$error: #d50032;
$warningLocked: #3f51b5;
$validated: green;
$waiting: purple;
$declined: red;
$arrow: #888888;
$errorLabel: #F44336;
$warningLabel: #ffa000;
$darkModeBg: #121212;
$darkModeText: #DDDDDD;
$darkModeCard: #1F1F1F;
$darkModeButton: #2D2D2D;
$darkModeLighter: #363636;
$darkModeButtonDisabled: #373737;
$darkModeScrollBar: #7f7f7f;
$darkModeScrollBarThumb: #a9a9a9;

$feedName: #2196f3;
$rowSelected: #3F80E1;
