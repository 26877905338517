.loginForm {
  width: 350px;
  margin: auto;
  padding-top: 50px;
}

.loginCard {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.loginHeader {
  padding: 1.5rem 0;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #32323e !important;
}

.gridContainer {
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #32323e !important;

  .form-group:not(:first-child) {
    margin-top: 10px;
  }
}

.buttonContainer {
  padding: 20px;
  align-items: center;
  background-color: #32323e !important;
}

.buttonContainer button {
  width: 100%;
  background-color: #abc7ff !important;
  color: #222327;
}

.error hr {
  border: 1px solid red;
}
