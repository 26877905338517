.accordion-header {
  margin-top: 8px !important;
}
.Mui-expanded {
  min-height: 0;
}
.Mui-expanded .MuiAccordionSummary-root.Mui-expanded {
  min-height: 0;
}
.MuiAccordionSummary-root {
  padding-right: 0px !important;
  color: #222327 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  background-color: #bcc2ff !important;
  margin: 0 !important;
  height: 31px !important;
  min-height: 31px !important;
}
.accordion-icon {
  height: 1.5em !important;
  width: 2em !important;
  color: #222327 !important;
}
.MuiAccordionDetails-root {
  padding: 12px !important;
  background-color: #24232a !important;
  overflow: hidden !important;
  border-radius: 0px 0px 5px 5px !important;
}
.MuiTableRow-root {
  text-transform: uppercase;
}
.MuiTableRow-root th {
  font-size: 12px;
  font-weight: 700;
  color: #bdbdbe;
}
