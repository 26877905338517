.MuiDialog-paper {
  max-width: 95% !important;
  width: 95% !important;
}

.MuiPaper-root {
  margin: 10px;
}

.checkLabel {
  padding-left: 20px;
  padding-bottom: 10px;
}

.IPField {
  padding-bottom: 10px !important;
  padding-left: 10px !important;
}

.MuiPaper-root {
  background-color: #32323e !important;
}

.audioContainer,
.facilitiesContainer,
.receptionContainer,
.serviceContainer,
.videosContainer {
  min-height: 24vh;
  background-size: 99px;
  border: 1px solid #7a7a7c;
  border-radius: 8px !important;
  background-color: #4f4f52 !important;
  margin-bottom: 8px !important;
  @media screen and (max-width: 1279px) {
    overflow: auto !important;
  }
}

.active {
  background-color: #3f80e1 !important;
  color: white !important;
}

.card-title {
  background-color: #bcc2ff;
  color: rgb(36, 35, 42);
  padding: 5px 10px !important;
  @media screen and (max-width: 1279px) {
    position: sticky;
    left: 0;
  }
}

.services-drawer {
  width: 100%;
}

.times {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
}

.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 13px;
}

.image {
  width: 154px;
  border-radius: 4px;
}

.arrows {
  width: 40px;
  height: 116px;
  background-color: #00458f;
  color: #d7e3ff;
  border-radius: 4px;
  text-align: center;
  line-height: 116px;
  cursor: pointer;
}

.arrows-disabled {
  width: 40px;
  height: 116px;
  background-color: #4f4f52;
  color: #d7e3ff;
  border-radius: 4px;
  text-align: center;
  line-height: 116px;
}
